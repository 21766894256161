import React from "react";

export const Button = (props) => (
  <button
    className={`bg-green-100 text-gray-600 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${props.className}`}
    {...props}
  >
    {props.children}
  </button>
);

export const SecondaryButton = (props) => (
  <button
    style={{ borderWidth: "1px" }}
    className="bg-transparente border-white text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    {...props}
  >
    {props.children}
  </button>
);

export const SecondaryGreenButton = (props) => (
  <button
    style={{ borderWidth: "1px" }}
    className="border-green text-green-100 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    {...props}
  >
    {props.children}
  </button>
);

export default Button;
