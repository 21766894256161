import "./App.css";
import "react-tooltip/dist/react-tooltip.css";

import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
  useParams,
} from "react-router-dom";
import pkg from "../package.json";
import CacheBuster from "react-cache-buster";
import Help from "./Help/index";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  Suspense,
  lazy,
} from "react";
import Loading from "./Loading";
import {
  gql,
  useLazyQuery,
  useMutation,
  useSubscription,
} from "@apollo/client";
import { hotjar } from "react-hotjar";
import NewVersionToast from "./NewVersionToast";
import Favicon from "react-favicon";

const Me = lazy(() => import("./Me/index"));
const QrCode = lazy(() => import("./QrCode.js"));
const MySchedules = lazy(() => import("./MySchedules.js"));
const MyEgLines = lazy(() => import("./MyEgLines.js"));
const MyEgLinesNew = lazy(() => import("./MyEgLinesNew.js"));
const PushNotifications = lazy(() => import("./PushNotifications.js"));
const NotFound = lazy(() => import("./NotFound.js"));
const Error = lazy(() => import("./Error.js"));
const Login = lazy(() => import("./Login/index"));
const EventConfirmation = lazy(() => import("./EventConfirmation/index"));
const HealthSurvey = lazy(() => import("./HealthSurvey/index"));
const ResetPassword = lazy(() => import("./ResetPassword/index"));
const VisitorSignup = lazy(() => import("./VisitorSignup/index"));
const Home = lazy(() => import("./Home/index"));
const VisitantHome = lazy(() => import("./VisitantHome/index"));
const Campus = lazy(() => import("./Campus/index"));
const SongsForm = lazy(() => import("./SongsForm/index"));
const EventsForm = lazy(() => import("./EventsForm/index"));
const Users = lazy(() => import("./Users/index"));
const Sessions = lazy(() => import("./Sessions/index"));
const Checklist = lazy(() => import("./Checklist/index"));
const ChecklistForm = lazy(() => import("./ChecklistForm/index"));
const VolunteerSessionMenu = lazy(() => import("./VolunteerSessionMenu/index"));
const CampusForm = lazy(() => import("./CampusForm/index"));
const ViewChecklist = lazy(() => import("./ViewChecklist/index"));
const UsersForm = lazy(() => import("./UsersForm/index"));
const SessionCoffee = lazy(() => import("./SessionCoffee/index"));
const SessionMultimedia = lazy(() => import("./SessionMultimedia/index"));
const SessionAudioDirectorship = lazy(() =>
  import("./SessionAudioDirectorship/index")
);
const SessionSocialMedia = lazy(() => import("./SessionSocialMedia/index"));
const SessionHotshare = lazy(() => import("./SessionHotshare/index"));
const SessionWelcome = lazy(() => import("./SessionWelcome/index"));
const VolunteerSessions = lazy(() => import("./VolunteerSessions/index"));
const VolunteerCampus = lazy(() => import("./VolunteerCampus/index"));
const SessionsForm = lazy(() => import("./SessionsForm/index"));
const SessionBackstage = lazy(() => import("./SessionBackstage/index"));
const SessionWorship = lazy(() => import("./SessionWorship/index"));
const SessionDirectorship = lazy(() => import("./SessionDirectorship/index"));
const SessionDirectorshipVolunteers = lazy(() =>
  import("./SessionDirectorshipVolunteers/index")
);
const SessionDirectorshipPlot = lazy(() =>
  import("./SessionDirectorshipPlot/index")
);
const SessionDirectorshipSpeech = lazy(() =>
  import("./SessionDirectorshipSpeech/index")
);
const SessionWorshipLyrics = lazy(() => import("./SessionWorshipLyrics/index"));
const Songs = lazy(() => import("./Songs/index"));
const Events = lazy(() => import("./Events/index"));
const Medias = lazy(() => import("./Medias/index"));
const MediasForm = lazy(() => import("./MediasForm/index"));
const Bookings = lazy(() => import("./Bookings/index"));
const BookingForm = lazy(() => import("./BookingForm/index"));
const SessionViewLyrics = lazy(() => import("./SessionViewLyrics/index"));
const CalendarList = lazy(() => import("./V2/CalendarList/index"));
const CalendarForm = lazy(() => import("./V2/CalendarForm/index"));
const DevotionalsForm = lazy(() => import("./V2/DevotionalsForm/index"));
const SessionMedia = lazy(() => import("./SessionMedia/index"));
const FillSessionChecklist = lazy(() => import("./FillSessionChecklist/index"));
const VolunteerScheduleList = lazy(() =>
  import("./V2/VolunteerScheduleList/index")
);

const EventScheduleList = lazy(() => import("./V2/EventScheduleList/index"));
const EventScheduleNew = lazy(() => import("./V2/EventScheduleNew/index"));

const VolunteerScheduleNew = lazy(() =>
  import("./V2/VolunteerScheduleNew/index")
);
const SessionAttendants = lazy(() => import("./SessionAttendants/index"));
const SessionMediaGallery = lazy(() => import("./SessionMediaGallery/index"));
const SessionVolunteers = lazy(() => import("./SessionVolunteers/index"));
const SessionChat = lazy(() => import("./SessionChat/index"));

if (process.env.REACT_APP_ENVIRONMENT === "prod") {
  hotjar.initialize("3349981", "6");
}

const EmailConfirmation = () => {
  const [userEmailConfirmationMutation, { loading, error, data }] = useMutation(
    gql`
      mutation UserEmailConfirmation($token: String!) {
        UserEmailConfirmation(token: $token) {
          _id
        }
      }
    `
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    if (!!token) {
      (async () => {
        userEmailConfirmationMutation({
          variables: {
            token,
          },
        });
      })();
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (data?.UserEmailConfirmation?._id) {
    return (
      <div className="p-8">
        <h1>E-mail confirmado com sucesso!</h1>
      </div>
    );
  }

  if (!!error) {
    return (
      <div className="p-8">
        <h1>Erro ao confirmar seu email!</h1>
      </div>
    );
  }

  return null;
};

const ProtectedAdminRoute = ({ children }) => {
  const { user } = getSession();

  if (!user || user?.role !== "admin") {
    return <Navigate to="/" replace />;
  }

  return children;
};

const ProtectedRoute = ({ children }) => {
  const { user } = getSession();

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const ProtectedVolunteerRoute = ({ children }) => {
  const { user } = getSession();

  if (!user || user?.role === "visitant") {
    return <Navigate to="/" replace />;
  }

  return children;
};

// move to session file
export const STORAGE_KEY = "__inc__";
export const clearSession = () =>
  localStorage.setItem(STORAGE_KEY, JSON.stringify({}));

export const setSessionArea = (sessionArea) =>
  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      ...getSession(),
      sessionArea,
    })
  );

export const getSession = () => {
  const storage = localStorage.getItem(STORAGE_KEY);
  if (storage && storage !== null) return JSON.parse(storage);

  return {};
};

export const SessionContext = createContext({
  session: null,
  setSession: (session) => {},
});

export const useSession = () => useContext(SessionContext);

// TODO: move from here
export const VIEW_SESSION = gql`
  query ViewSession($_id: String!) {
    ViewSession(_id: $_id) {
      _id
      totalAttendants

      visitors {
        _id
        name
        email
        avatar
        phone
        source
        sessionFeeling
      }

      director {
        _id
        name
      }
      title
      campus {
        _id
        name
      }
      dateTime
      events {
        _id
        event
        note
        notes
        song {
          _id
          name
        }
        media {
          _id
          name
          url
        }
        durationInMinutes
        volunteer {
          volunteer {
            _id
            name
            email
            avatar
          }
          available
        }
      }
      speech {
        medias {
          _id
          path
          name
          size
          type
          url
        }
        notes
      }
      media {
        medias {
          path
          name
          size
          type
          url
        }
        volunteers {
          volunteer {
            _id
            name
            email
            avatar
          }
          available
        }
      }
      worship {
        volunteers {
          volunteer {
            _id
            name
            email
            avatar
          }
          available
        }
        checklist {
          checklist {
            _id
            name
            area
          }
          checkin {
            _id
            item
            checked
          }
          checkout {
            _id
            item
            checked
          }
        }
      }
      welcome {
        checklist {
          checklist {
            _id
            name
            area
          }
          checkin {
            _id
            item
            checked
          }
          checkout {
            _id
            item
            checked
          }
        }
        volunteers {
          volunteer {
            _id
            name
            email
            avatar
          }
          available
        }
      }
      hotshare {
        checklist {
          checklist {
            _id
            name
            area
          }
          checkin {
            _id
            item
            checked
          }
          checkout {
            _id
            item
            checked
          }
        }
        volunteers {
          volunteer {
            _id
            name
            email
            avatar
          }
          available
        }
      }
      coffee {
        checklist {
          checklist {
            _id
            name
            area
          }
          checkin {
            _id
            item
            checked
          }
          checkout {
            _id
            item
            checked
          }
        }
        volunteers {
          volunteer {
            _id
            name
            email
            avatar
          }
          available
        }
      }
      multimedia {
        checklist {
          checklist {
            _id
            name
            area
          }
          checkin {
            _id
            item
            checked
          }
          checkout {
            _id
            item
            checked
          }
        }
        volunteers {
          volunteer {
            _id
            name
            email
            avatar
          }
          available
        }
      }

      audioDirectorship {
        checklist {
          checklist {
            _id
            name
            area
          }
          checkin {
            _id
            item
            checked
          }
          checkout {
            _id
            item
            checked
          }
        }
        volunteers {
          volunteer {
            _id
            name
            email
            avatar
          }
          available
        }
      }

      socialMedia {
        checklist {
          checklist {
            _id
            name
            area
          }
          checkin {
            _id
            item
            checked
          }
          checkout {
            _id
            item
            checked
          }
        }
        volunteers {
          volunteer {
            _id
            name
            email
            avatar
          }
          available
        }
      }
      backstage {
        checklist {
          checklist {
            _id
            name
            area
          }
          checkin {
            _id
            item
            checked
          }
          checkout {
            _id
            item
            checked
          }
        }
        volunteers {
          volunteer {
            _id
            name
            email
            avatar
          }
          available
        }
      }
    }
  }
`;

const App = () => {
  const [session, setSession] = useState(null);

  const [viewSession, { loading, data, error }] = useLazyQuery(VIEW_SESSION);

  const { data: newAppVersionPublished } = useSubscription(
    gql`
      subscription {
        NewAppVersionPublished
      }
    `
  );

  const [_, f, s, id] = window.location.pathname.split("/");

  useEffect(() => {
    const fetchSession = async () => {
      const { data } = await viewSession({
        variables: {
          _id: id,
        },
      });

      if (!error && data?.ViewSession) {
        setSession(data.ViewSession);
      }
    };

    if (f === "volunteer" && s === "sessions" && id && !session) {
      fetchSession();
    }
  }, []);

  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";

  if (loading) return <Loading />;

  return (
    <CacheBuster
      currentVersion={pkg.version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<Loading />}
      metaFileDirectory={"."}
    >
      <SessionContext.Provider value={{ session, setSession }}>
        {window?.location?.pathname?.match("visitor-signup") && (
          <Favicon url="favicon-red.png" />
        )}
        {!window?.location?.pathname?.match("visitor-signup") && (
          <Favicon url="favicon.png" />
        )}
        {newAppVersionPublished?.NewAppVersionPublished && <NewVersionToast />}
        <Suspense fallback={null}>
          <Routes>
            <Route path={"/reset-password"} element={<ResetPassword />} />
            <Route path={"/"} element={<Login />} />
            <Route
              path={"/auth/email-confirmation"}
              element={<EmailConfirmation />}
            />
            <Route path={"/oh-no"} element={<Error />} />
            <Route
              path="health-survey"
              element={
                <ProtectedRoute>
                  <HealthSurvey />
                </ProtectedRoute>
              }
            />
            <Route
              path="home"
              element={
                <ProtectedRoute>
                  <Home session={session} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/visitor/home"
              element={
                <ProtectedRoute>
                  <VisitantHome />
                </ProtectedRoute>
              }
            />
            <Route
              path="help"
              element={
                <ProtectedRoute>
                  <Help.Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/worship/lyrics/:songId"
              element={
                <ProtectedRoute>
                  <SessionViewLyrics session={session} />
                </ProtectedRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/directorship"
              element={
                <ProtectedVolunteerRoute>
                  <SessionDirectorship pathList={["Directorship"]} />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions"
              element={
                <ProtectedVolunteerRoute>
                  <VolunteerSessions />
                </ProtectedVolunteerRoute>
              }
            />
            <Route path="visitor-signup" element={<VisitorSignup />} />
            <Route
              path="volunteer/campus"
              element={
                <ProtectedVolunteerRoute>
                  <VolunteerCampus />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id"
              element={<VolunteerSessionMenu pathList={[]} />}
            />
            <Route
              path="volunteer/sessions/:id/directorship/speech"
              element={
                <ProtectedVolunteerRoute>
                  <SessionDirectorshipSpeech
                    pathList={["Directorship", "Speech"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/directorship/plot"
              element={
                <ProtectedVolunteerRoute>
                  <SessionDirectorshipPlot
                    session={session}
                    pathList={["Directorship", "Plot"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/directorship/volunteers"
              element={
                <ProtectedVolunteerRoute>
                  <SessionDirectorshipVolunteers
                    session={session}
                    pathList={["Directorship", "Volunteers"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/media/gallery"
              element={
                <ProtectedVolunteerRoute>
                  <SessionMediaGallery pathList={["Media", "Gallery"]} />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/media"
              element={
                <ProtectedVolunteerRoute>
                  <SessionMedia pathList={["Media"]} />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/backstage"
              element={
                <ProtectedVolunteerRoute>
                  <SessionBackstage pathList={["Roadies"]} />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/welcome"
              element={
                <ProtectedVolunteerRoute>
                  <SessionWelcome pathList={["Welcome"]} />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/welcome/attendants"
              element={
                <ProtectedVolunteerRoute>
                  <SessionAttendants
                    area="welcome"
                    pathList={["Welcome", "Attendants"]}
                    session={session}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/welcome/checklist"
              element={
                <ProtectedVolunteerRoute>
                  <FillSessionChecklist
                    area="welcome"
                    pathList={["Welcome", "Checklist"]}
                    session={session}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/welcome/volunteers"
              element={
                <ProtectedVolunteerRoute>
                  <SessionVolunteers
                    area="welcome"
                    pathList={["Welcome", "Volunteers"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/coffee"
              element={
                <ProtectedVolunteerRoute>
                  <SessionCoffee pathList={["Coffee"]} />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/coffee/checklist"
              element={
                <ProtectedVolunteerRoute>
                  <FillSessionChecklist
                    area="coffee"
                    pathList={["Coffee", "Checklist"]}
                    session={session}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/coffee/volunteers"
              element={
                <ProtectedVolunteerRoute>
                  <SessionVolunteers
                    area="coffee"
                    pathList={["Coffee", "Volunteers"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/audio-directorship"
              element={
                <ProtectedVolunteerRoute>
                  <SessionAudioDirectorship pathList={["Audio Directorship"]} />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/audio-directorship/checklist"
              element={
                <ProtectedVolunteerRoute>
                  <FillSessionChecklist
                    area="audioDirectorship"
                    pathList={["Audio Directorship", "Checklist"]}
                    session={session}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/audio-directorship/volunteers"
              element={
                <ProtectedVolunteerRoute>
                  <SessionVolunteers
                    area="audioDirectorship"
                    pathList={["Audio Directorship", "Volunteers"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/multimedia"
              element={
                <ProtectedVolunteerRoute>
                  <SessionMultimedia pathList={["Multimedia"]} />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/multimedia/checklist"
              element={
                <ProtectedVolunteerRoute>
                  <FillSessionChecklist
                    area="multimedia"
                    pathList={["Multimedia", "Checklist"]}
                    session={session}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/multimedia/volunteers"
              element={
                <ProtectedVolunteerRoute>
                  <SessionVolunteers
                    area="multimedia"
                    pathList={["Multimedia", "Volunteers"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/social-media"
              element={
                <ProtectedVolunteerRoute>
                  <SessionSocialMedia pathList={["Social media"]} />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/social-media/checklist"
              element={
                <ProtectedVolunteerRoute>
                  <FillSessionChecklist
                    area="socialMedia"
                    pathList={["Social media", "Checklist"]}
                    session={session}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/social-media/volunteers"
              element={
                <ProtectedVolunteerRoute>
                  <SessionVolunteers
                    area="socialMedia"
                    pathList={["Social media", "Volunteers"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/hotshare"
              element={
                <ProtectedVolunteerRoute>
                  <SessionHotshare pathList={["Hot share"]} />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/hotshare/checklist"
              element={
                <ProtectedVolunteerRoute>
                  <FillSessionChecklist
                    area="hotshare"
                    pathList={["Hot share", "Checklist"]}
                    session={session}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/hotshare/volunteers"
              element={
                <ProtectedVolunteerRoute>
                  <SessionVolunteers
                    area="hotshare"
                    pathList={["Hot share", "Volunteers"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/backstage/checklist"
              element={
                <ProtectedVolunteerRoute>
                  <FillSessionChecklist
                    area="backstage"
                    pathList={["Roadies", "Checklist"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/worship"
              element={
                <ProtectedVolunteerRoute>
                  <SessionWorship pathList={["Worship"]} />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/worship/lyrics"
              element={
                <ProtectedVolunteerRoute>
                  <SessionWorshipLyrics
                    session={session}
                    pathList={["Worship", "Lyrics"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/worship/checklist"
              element={
                <ProtectedVolunteerRoute>
                  <FillSessionChecklist
                    area="worship"
                    pathList={["Worship", "Checklist"]}
                    session={session}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/worship/volunteers"
              element={
                <ProtectedVolunteerRoute>
                  <SessionVolunteers
                    area="worship"
                    pathList={["Worship", "Volunteers"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/media/volunteers"
              element={
                <ProtectedVolunteerRoute>
                  <SessionVolunteers
                    area="media"
                    pathList={["Media", "Volunteers"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/chat"
              element={
                <ProtectedVolunteerRoute>
                  <SessionChat />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="help/volunteers/session/roadies"
              element={
                <ProtectedRoute>
                  <Help.Volunteers.Session.Roadies />
                </ProtectedRoute>
              }
            />
            <Route
              path="help/volunteers/session/worship"
              element={
                <ProtectedRoute>
                  <Help.Volunteers.Session.Worship />
                </ProtectedRoute>
              }
            />
            <Route
              path="help/volunteers/session/welcome"
              element={
                <ProtectedRoute>
                  <Help.Volunteers.Session.Welcome />
                </ProtectedRoute>
              }
            />
            <Route
              path="help/volunteers/session/hotshare"
              element={
                <ProtectedRoute>
                  <Help.Volunteers.Session.Hotshare />
                </ProtectedRoute>
              }
            />
            <Route
              path="help/volunteers/session/coffee"
              element={
                <ProtectedRoute>
                  <Help.Volunteers.Session.Coffee />
                </ProtectedRoute>
              }
            />
            <Route
              path="help/volunteers/session/social-media"
              element={
                <ProtectedRoute>
                  <Help.Volunteers.Session.SocialMedia />
                </ProtectedRoute>
              }
            />
            <Route
              path="help/volunteers/session/multimedia"
              element={
                <ProtectedRoute>
                  <Help.Volunteers.Session.Multimedia />
                </ProtectedRoute>
              }
            />
            <Route
              path="help/volunteers/session/audio-directorship"
              element={
                <ProtectedRoute>
                  <Help.Volunteers.Session.AudioDirectorship />
                </ProtectedRoute>
              }
            />
            <Route
              path="volunteer/sessions/:id/backstage/volunteers"
              element={
                <ProtectedVolunteerRoute>
                  <SessionVolunteers
                    area="backstage"
                    pathList={["Roadies", "Volunteers"]}
                  />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="/v2/volunteer-schedule"
              element={
                <ProtectedAdminRoute>
                  <VolunteerScheduleList />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="/v2/volunteer-schedule/:id"
              element={
                <ProtectedAdminRoute>
                  <VolunteerScheduleNew />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="/v2/event-schedule"
              element={
                <ProtectedAdminRoute>
                  <EventScheduleList />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="/v2/calendar"
              element={
                <ProtectedAdminRoute>
                  <CalendarList />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="/v2/devotionals/:id"
              element={
                <ProtectedAdminRoute>
                  <DevotionalsForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="/v2/calendar/:id"
              element={
                <ProtectedAdminRoute>
                  <CalendarForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="/v2/event-schedule/:id"
              element={
                <ProtectedAdminRoute>
                  <EventScheduleNew />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="/private/qrcode"
              element={
                <ProtectedAdminRoute>
                  <QrCode />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="/private/my-schedules"
              element={
                <ProtectedAdminRoute>
                  <MySchedules />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="/private/my-eg-lines/new"
              element={
                <ProtectedAdminRoute>
                  <MyEgLinesNew />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="/private/my-eg-lines"
              element={
                <ProtectedAdminRoute>
                  <MyEgLines />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="/private/push-notifications"
              element={
                <ProtectedAdminRoute>
                  <PushNotifications />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="campus"
              element={
                <ProtectedAdminRoute>
                  <Campus />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="sessions"
              element={
                <ProtectedAdminRoute>
                  <Sessions />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="sessions/new"
              element={
                <ProtectedAdminRoute>
                  <SessionsForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="sessions/:id"
              element={
                <ProtectedAdminRoute>
                  <SessionsForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="users"
              element={
                <ProtectedAdminRoute>
                  <Users />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="users/new"
              element={
                <ProtectedAdminRoute>
                  <UsersForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="users/:id"
              element={
                <ProtectedAdminRoute>
                  <UsersForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="bookings"
              element={
                <ProtectedVolunteerRoute>
                  <Bookings />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="bookings/new"
              element={
                <ProtectedVolunteerRoute>
                  <BookingForm />
                </ProtectedVolunteerRoute>
              }
            />
            <Route
              path="events"
              element={
                <ProtectedAdminRoute>
                  <Events />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="event-confirmation/:id/:source/:userId"
              element={<EventConfirmation />}
            />
            <Route
              path="events/new"
              element={
                <ProtectedAdminRoute>
                  <EventsForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="events/edit/:id"
              element={
                <ProtectedAdminRoute>
                  <EventsForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="songs"
              element={
                <ProtectedAdminRoute>
                  <Songs />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="medias"
              element={
                <ProtectedAdminRoute>
                  <Medias />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="songs/new"
              element={
                <ProtectedAdminRoute>
                  <SongsForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="medias/new"
              element={
                <ProtectedAdminRoute>
                  <MediasForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="songs/edit/:id"
              element={
                <ProtectedAdminRoute>
                  <SongsForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="checklist"
              element={
                <ProtectedAdminRoute>
                  <Checklist />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="checklist/:id"
              element={
                <ProtectedAdminRoute>
                  <ViewChecklist />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="checklist/edit/:id"
              element={
                <ProtectedAdminRoute>
                  <ChecklistForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="checklist/new"
              element={
                <ProtectedAdminRoute>
                  <ChecklistForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="campus/new"
              element={
                <ProtectedAdminRoute>
                  <CampusForm />
                </ProtectedAdminRoute>
              }
            />
            <Route
              path="me"
              element={
                <ProtectedRoute>
                  <Me />
                </ProtectedRoute>
              }
            />
            <Route
              path="campus/:id"
              element={
                <ProtectedAdminRoute>
                  <CampusForm />
                </ProtectedAdminRoute>
              }
            />
            <Route path="*" exact={true} element={<NotFound />} />
          </Routes>
        </Suspense>
      </SessionContext.Provider>
    </CacheBuster>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
