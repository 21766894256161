/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { BoltIcon } from "@heroicons/react/24/solid";
import Button from "./Button";

export const NewVersionToast = () => {
  return (
    <div
      className={`flex justify-center fixed`}
      css={css`
        height: 100%;
        width: 0;
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        background: rgba(19, 20, 30, 0.8);
        width: 100%;
        height: 100%;
      `}
    >
      <div
        className={`bg-gray3 rounded-xl px-8 py-4 flex justify-center`}
        css={css`
          max-width: 350px;
          text-align: center;
          top: 40%;
          position: absolute;
        `}
      >
        <div className={`p-2 flex flex-col justify-center`}>
          <div className={`mb-2 flex justify-center items-center align-itens`}>
            <BoltIcon className="h-5 w-5 card-header-actions mr-2" />
            <span
              css={css`
                font-size: 20px;
              `}
            >
              New version available
            </span>
          </div>
          <div>
            <h2>An improved version is now available, refresh to update!</h2>
          </div>
          <div className={`mt-5 flex justify-center`}>
            <Button
              onClick={() => {
                window.location.reload(true);
              }}
            >
              Refresh
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewVersionToast;
