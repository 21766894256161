/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { ApolloProvider } from "@apollo/client";
import { BrowserTracing } from "@sentry/tracing";
import { browserName, CustomView } from "react-device-detect";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

import "./index.css";
import App from "./App";
import { buildClient } from "./apollo";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_ENVIRONMENT === "prod") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        blockAllMedia: false,
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}

const client = buildClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ApolloProvider client={client}>
    {/* <React.StrictMode> */}
    <CustomView condition={browserName !== "Chrome"}>
      <div
        css={css`
          background-color: #d23201;
          top: 0;
          width: 100%;
          padding: 10px;
          display: flex;
          align-items: center;
        `}
      >
        <p>
          <ExclamationTriangleIcon className="w-4 h-4 mr-2 inline" />
          We recommend you use&nbsp;
          <b>
            <a
              className="underline"
              href="https://www.google.com/chrome"
              target="_blank"
              rel="noreferrer"
            >
              Chrome
            </a>
          </b>
          &nbsp;to have a better experience!
        </p>
      </div>
    </CustomView>
    <App />
    {/* </React.StrictMode> */}
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
