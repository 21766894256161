/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Fragment } from "react";

const Loading = ({ theme }) => (
  <Fragment>
    <div
      css={css`
        background: ${theme === "red" ? "#e24c26" : "#3c3c46"};
        left: 0;
        opacity: 0.5;
        z-index: 10;
        position: fixed;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
      `}
    ></div>
    {theme !== "red" ? (
      <img
        src="/loading.gif"
        css={css`
          z-index: 9999;
          width: 200px;
          top: 40%;
          position: fixed;
          left: 50%;
          transform: translate(-50%, 0);
        `}
      />
    ) : null}
  </Fragment>
);

export default Loading;
