/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Bars3Icon,
  FilmIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  HomeIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useRef, useState, Fragment } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { getSession } from "../App";

const Feedback = () => {
  return (
    <div className="mt-10">
      <p>Esse artigo foi útil?</p>
      <div className="mt-4">
        <button>
          <HandThumbUpIcon className="h-5 w-5" /> Sim
        </button>
        <button className="ml-4">
          <HandThumbDownIcon className="h-5 w-5" /> Não
        </button>
      </div>
    </div>
  );
};

const Checklist = () => {
  return (
    <div>
      <h4 className="font-bold text-base mt-2">Checklist</h4>
      <p>
        No menu <b>checklist</b> você vai encontrar o passo a passo que deve ser
        feito antes e depois da sessão.
      </p>
    </div>
  );
};

const Volunteers = () => {
  return (
    <div>
      <h4 className="font-bold text-base mt-2">Volunteers</h4>
      <p>
        Aqui, você vai encontrar todos os colegas que estão escalados para
        servir com você e também poderá fazer o seu <b>check-in</b> quando
        chegar no cinema.
      </p>
    </div>
  );
};

const Lyrics = () => {
  return (
    <div>
      <h4 className="font-bold text-base mt-2">Lyrics</h4>
      <p>come soon!</p>
    </div>
  );
};

const Header = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const { user } = getSession();
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = () => {
    setMenuOpen(true);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (menuOpen && ref.current && !ref.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [menuOpen]);

  return (
    <Fragment>
      {user?._id === undefined && <Navigate to="/" />}
      {menuOpen && (
        <div
          ref={ref}
          css={css`
            position: absolute;
            right: 0;
            height: 100%;
            width: 50%;
            max-width: 250px;
            background: #13141e;
            z-index: 1;
          `}
        >
          <button
            className="font-bold"
            css={css`
              text-decoration: bold;
              display: flex;
              align-items: center;
              padding: 15px;
            `}
          >
            <FilmIcon className="absolute h-5 w-5 text-gray-100 focus:outline-none relative mr-2" />{" "}
            Session
          </button>
          <ul>
            <li
              className="side-menu-item text-sm"
              css={css`
                padding-left: 45px;
              `}
            >
              <button
                onClick={() => navigate("/help/volunteers/session/roadies")}
                css={css`
                  display: flex;
                  align-items: center;
                `}
                className="w-full"
              >
                Roadies
              </button>
            </li>
            <li
              className="side-menu-item text-sm"
              css={css`
                padding-left: 45px;
              `}
            >
              <button
                onClick={() => navigate("/help/volunteers/session/media")}
                css={css`
                  display: flex;
                  align-items: center;
                `}
                className="w-full"
              >
                Media
              </button>
            </li>
            <li
              className="side-menu-item text-sm"
              css={css`
                padding-left: 45px;
              `}
            >
              <button
                onClick={() => navigate("/help/volunteers/session/welcome")}
                css={css`
                  display: flex;
                  align-items: center;
                `}
                className="w-full"
              >
                Welcome
              </button>
            </li>
            <li
              className="side-menu-item text-sm"
              css={css`
                padding-left: 45px;
              `}
            >
              <button
                onClick={() => navigate("/help/volunteers/session/hotshare")}
                css={css`
                  display: flex;
                  align-items: center;
                `}
                className="w-full"
              >
                Hot share
              </button>
            </li>
            <li
              className="side-menu-item text-sm"
              css={css`
                padding-left: 45px;
              `}
            >
              <button
                onClick={() => navigate("/help/volunteers/session/coffee")}
                css={css`
                  display: flex;
                  align-items: center;
                `}
                className="w-full"
              >
                Coffee
              </button>
            </li>
            <li
              className="side-menu-item text-sm"
              css={css`
                padding-left: 45px;
              `}
            >
              <button
                onClick={() =>
                  navigate("/help/volunteers/session/social-media")
                }
                css={css`
                  display: flex;
                  align-items: center;
                `}
                className="w-full"
              >
                Social media
              </button>
            </li>
            <li
              className="side-menu-item text-sm"
              css={css`
                padding-left: 45px;
              `}
            >
              <button
                onClick={() => navigate("/help/volunteers/session/multimedia")}
                css={css`
                  display: flex;
                  align-items: center;
                `}
                className="w-full"
              >
                Multimedia
              </button>
            </li>

            <li
              className="side-menu-item text-sm"
              css={css`
                padding-left: 45px;
              `}
            >
              <button
                onClick={() =>
                  navigate("/help/volunteers/session/audio-directorship")
                }
                css={css`
                  display: flex;
                  align-items: center;
                `}
                className="w-full"
              >
                Audio Directorship
              </button>
            </li>

            <li
              className="side-menu-item text-sm"
              css={css`
                padding-left: 45px;
              `}
            >
              <button
                onClick={() => navigate("/help/volunteers/session/worship")}
                css={css`
                  display: flex;
                  align-items: center;
                `}
                className="w-full"
              >
                Worship
              </button>
            </li>
            <li
              className="side-menu-item text-sm"
              css={css`
                padding-left: 45px;
              `}
            >
              <button
                onClick={() =>
                  navigate("/help/volunteers/session/directorship")
                }
                css={css`
                  display: flex;
                  align-items: center;
                `}
                className="w-full"
              >
                Directorship
              </button>
            </li>
          </ul>
        </div>
      )}
      <header>
        <button
          onClick={openMenu}
          css={css`
            float: right;
          `}
          className="pt-8 pr-8"
        >
          <Bars3Icon className="top-5 right-5 h-8 w-8 text-gray-100 focus:outline-none" />
        </button>
        <div
          className="p-8 pb-0"
          css={css`
            max-width: 90%;
          `}
        >
          <h1 className="text-4xl">
            Welcome to <span className="text-green-100">our Help</span> area
          </h1>
        </div>
      </header>
    </Fragment>
  );
};

export default {
  Home: () => {
    return (
      <div>
        <Header />

        <div className="p-8 pt-0">
          <h3 className="mt-2 text-lg">
            Here you will find resources to help you better understand our{" "}
            <b className="text-green-100">App</b>.
          </h3>
        </div>
      </div>
    );
  },
  Volunteers: {
    Session: {
      Roadies: () => {
        return (
          <div>
            <Header />

            <div className="p-8 pt-0">
              <h3 className="mt-2 text-lg">
                We are glad you are helping our{" "}
                <b className="text-green-100">Roadies</b> backstage!
              </h3>

              <div>
                <p className="mt-6">
                  O backstage de <b>Roadies</b> é o time responsável por gerir e
                  organizar todos os artefatos de palco, como luzes, máquina de
                  fumaça, materiais de som, etc.
                </p>
                <div>
                  <h3 className="font-bold text-lg mt-4">Heads</h3>
                  <h4 className="font-bold text-base mt-2">Campus Curitiba</h4>
                  <ul>
                    <li>Giovane Rossatto</li>
                  </ul>
                  <h4 className="font-bold text-base mt-2">Campus Ventura</h4>
                  <ul>
                    <li>Cleriton Araujo Agostinho</li>
                  </ul>
                </div>
                <div>
                  <h3 className="font-bold text-lg mt-4">
                    O que fazer no app?
                  </h3>
                  <Checklist />
                  <Volunteers />
                </div>
              </div>

              <video controls className="mt-10 w-full h-22">
                <source
                  src="/help-center/checklist-volunteers.mp4"
                  type="video/mp4"
                />
              </video>

              {/* <Feedback /> */}
            </div>
          </div>
        );
      },
      Hotshare: () => {
        return (
          <div>
            <Header />

            <div className="p-8 pt-0">
              <h3 className="mt-2 text-lg">
                We are glad you are helping our{" "}
                <b className="text-green-100">Hotshare</b> backstage!
              </h3>

              <div>
                <p className="mt-6">
                  O backstage de <b>Hotshare</b> é o nosso time de Profetas.
                  Ficam no hall e durante toda a sessão focados na instrução do
                  Espírito Santo ministrando palavras, desenhos, orações e
                  profetizando na vida das pessoas.
                </p>
                <div>
                  <h3 className="font-bold text-lg mt-4">Heads</h3>
                  <h4 className="font-bold text-base mt-2">Campus Ventura</h4>
                  <ul>
                    <li>Tiago Macedo dos Santos</li>
                  </ul>
                </div>
                <div>
                  <h3 className="font-bold text-lg mt-4">
                    O que fazer no app?
                  </h3>
                  <Checklist />
                  <Volunteers />
                </div>
              </div>

              <video controls className="mt-10 w-full h-22">
                <source
                  src="/help-center/checklist-volunteers.mp4"
                  type="video/mp4"
                />
              </video>

              {/* <Feedback /> */}
            </div>
          </div>
        );
      },
      Coffee: () => {
        return (
          <div>
            <Header />

            <div className="p-8 pt-0">
              <h3 className="mt-2 text-lg">
                We are glad you are helping our{" "}
                <b className="text-green-100">Coffee</b> backstage!
              </h3>

              <div>
                <p className="mt-6">
                  O backstage de <b>Coffee</b> é o time que nos ajuda na
                  recepção das pessoas no hall. Sempre com aquele cafézinho
                  especial e um sorriso no rosto.
                </p>
                <div>
                  <h3 className="font-bold text-lg mt-4">Heads</h3>
                  <h4 className="font-bold text-base mt-2">Campus Curitiba</h4>
                  <ul>
                    <li>Eduardo</li>
                  </ul>
                </div>
                <div>
                  <h3 className="font-bold text-lg mt-4">
                    O que fazer no app?
                  </h3>
                  <Checklist />
                  <Volunteers />
                </div>
              </div>

              <video controls className="mt-10 w-full h-22">
                <source
                  src="/help-center/checklist-volunteers.mp4"
                  type="video/mp4"
                />
              </video>

              {/* <Feedback /> */}
            </div>
          </div>
        );
      },
      SocialMedia: () => {
        return (
          <div>
            <Header />

            <div className="p-8 pt-0">
              <h3 className="mt-2 text-lg">
                We are glad you are helping our{" "}
                <b className="text-green-100">Social Media</b> backstage!
              </h3>

              <div>
                <p className="mt-6">
                  O backstage de <b>Social Media</b> é o nosso time de midias
                  sociais. Responsável por todas as públicações, criação de
                  conteúdo, e todas as novidades que ficamos sabendo pelo
                  Instagram e WhatsApp.
                </p>
                <div>
                  <h3 className="font-bold text-lg mt-4">Heads</h3>
                  <h4 className="font-bold text-base mt-2">Campus Curitiba</h4>
                  <ul>
                    <li>Laís Siegel</li>
                  </ul>
                </div>
                <div>
                  <h3 className="font-bold text-lg mt-4">
                    O que fazer no app?
                  </h3>
                  <Checklist />
                  <Volunteers />
                </div>
              </div>

              <video controls className="mt-10 w-full h-22">
                <source
                  src="/help-center/checklist-volunteers.mp4"
                  type="video/mp4"
                />
              </video>

              {/* <Feedback /> */}
            </div>
          </div>
        );
      },
      Multimedia: () => {
        return (
          <div>
            <Header />

            <div className="p-8 pt-0">
              <h3 className="mt-2 text-lg">
                We are glad you are helping our{" "}
                <b className="text-green-100">Multimedia</b> backstage!
              </h3>

              <div>
                <p className="mt-6">
                  O backstage de <b>Multimedia</b> é o nosso time de projeção.
                  Responsável por passar todos os conteúdos de mídia durante a
                  sessão, como videos, imagens, passagens bíblicas, etc.
                </p>
                <div>
                  <h3 className="font-bold text-lg mt-4">Heads</h3>
                  <h4 className="font-bold text-base mt-2">Campus Curitiba</h4>
                  <ul>
                    <li>Leonardo Oliveira</li>
                  </ul>
                  <h4 className="font-bold text-base mt-2">Campus Ventura</h4>
                  <ul>
                    <li>Leonardo Oliveira</li>
                  </ul>
                </div>
                <div>
                  <h3 className="font-bold text-lg mt-4">
                    O que fazer no app?
                  </h3>
                  <Checklist />
                  <Volunteers />
                </div>
              </div>

              <video controls className="mt-10 w-full h-22">
                <source
                  src="/help-center/checklist-volunteers.mp4"
                  type="video/mp4"
                />
              </video>

              {/* <Feedback /> */}
            </div>
          </div>
        );
      },
      AudioDirectorship: () => {
        return (
          <div>
            <Header />

            <div className="p-8 pt-0">
              <h3 className="mt-2 text-lg">
                We are glad you are helping our{" "}
                <b className="text-green-100">Audio Directorship</b> backstage!
              </h3>

              <div>
                <p className="mt-6">{/*text here*/}</p>
                <div>
                  <h3 className="font-bold text-lg mt-4">Heads</h3>
                  <h4 className="font-bold text-base mt-2">Campus Curitiba</h4>
                  <ul>
                    <li>Leonardo Oliveira</li>
                  </ul>
                  <h4 className="font-bold text-base mt-2">Campus Ventura</h4>
                  <ul>
                    <li>Leonardo Oliveira</li>
                  </ul>
                </div>
                <div>
                  <h3 className="font-bold text-lg mt-4">
                    O que fazer no app?
                  </h3>
                  <Checklist />
                  <Volunteers />
                </div>
              </div>

              <video controls className="mt-10 w-full h-22">
                <source
                  src="/help-center/checklist-volunteers.mp4"
                  type="video/mp4"
                />
              </video>

              {/* <Feedback /> */}
            </div>
          </div>
        );
      },

      Welcome: () => {
        return (
          <div>
            <Header />

            <div className="p-8 pt-0">
              <h3 className="mt-2 text-lg">
                We are glad you are helping our{" "}
                <b className="text-green-100">Welcome</b> backstage!
              </h3>

              <div>
                <p className="mt-6">
                  O backstage de <b>Welcome</b> é o time responsável por receber
                  as pessoas no hall, é a nossa faixada na hora da sessão, eles
                  ajudam com os visitantes na hora do boas-vindas, auxiliam no
                  be-generous and na coleta e engajamento dos visitantes no fim
                  com o open-house.
                </p>
                <div>
                  <h3 className="font-bold text-lg mt-4">Heads</h3>
                  <h4 className="font-bold text-base mt-2">CHF</h4>
                  <ul>
                    <li>Charles Rodrigo Martins Camargo</li>
                  </ul>
                  <h4 className="font-bold text-base mt-2">Central City</h4>
                  <ul>
                    <li>Douglas Fabiano Oliveira de Araujo</li>
                  </ul>
                  <h4 className="font-bold text-base mt-2">Campus Curitiba</h4>
                  <ul>
                    <li>Flávia Carvalho de Araújo</li>
                    <li>João Victor Bissoloti</li>
                  </ul>
                  <h4 className="font-bold text-base mt-2">Campus Ventura</h4>
                  <ul>
                    <li>Philipe Augusto de Souza burato</li>
                    <li>Priscila Merigo</li>
                    <li>Renan Pagno Silva</li>
                  </ul>
                </div>
                <div>
                  <h3 className="font-bold text-lg mt-4">
                    O que fazer no app?
                  </h3>
                  <Checklist />
                  <Volunteers />
                </div>
              </div>

              <video controls className="mt-10 w-full h-22">
                <source
                  src="/help-center/checklist-volunteers.mp4"
                  type="video/mp4"
                />
              </video>

              {/* <Feedback /> */}
            </div>
          </div>
        );
      },
      Worship: () => {
        return (
          <div>
            <Header />

            <div className="p-8 pt-0">
              <h3 className="mt-2 text-lg">
                We are glad you are helping our{" "}
                <b className="text-green-100">Worship</b> backstage!
              </h3>

              <div>
                <p className="mt-6">
                  O backstage de <b>Worship</b> é o time responsável por nos
                  entronizar ao Pai através do louvor e adoração.
                </p>
                <div>
                  <h3 className="font-bold text-lg mt-4">Heads</h3>
                  <h4 className="font-bold text-base mt-2">CHF</h4>
                  <ul>
                    <li>Léo</li>
                  </ul>
                  <h4 className="font-bold text-base mt-2">Campus Curitiba</h4>
                  <ul>
                    <li>Gii Araújo</li>
                  </ul>
                  <h4 className="font-bold text-base mt-2">Campus Ventura</h4>
                  <ul>
                    <li>Bruno Araújo</li>
                  </ul>
                </div>
                <div>
                  <h3 className="font-bold text-lg mt-4">
                    O que fazer no app?
                  </h3>
                  <Checklist />
                  <Volunteers />
                  <Lyrics />
                </div>
              </div>

              <video controls className="mt-10 w-full h-22">
                <source
                  src="/help-center/checklist-volunteers.mp4"
                  type="video/mp4"
                />
              </video>

              {/* <Feedback /> */}
            </div>
          </div>
        );
      },
    },
  },
};
