import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import {
  split,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { createClient } from "graphql-ws";

import { clearSession, getSession } from "./App";

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_INC_API_SOCKET,
    connectionParams: {
      // authToken: user.authToken,
    },
  })
);

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_INC_API,
  credentials: "same-origin",
});

const authLink = () =>
  setContext((_, { headers }) => {
    const session = getSession();

    return {
      headers: {
        ...headers,
        authorization: session?.token ? `Bearer ${session?.token}` : "",
      },
    };
  });

const errorLink = onError(({ networkError, operation }) => {
  if (
    networkError?.statusCode === 401 &&
    operation?.operationName !== "Login"
  ) {
    clearSession();
    window.location.reload();
    return;
  }

  if (networkError?.message === "Failed to fetch") {
    window.location.href = "/oh-no";
    return;
  }
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const buildClient = () =>
  new ApolloClient({
    link: authLink().concat(errorLink).concat(splitLink),
    cache: new InMemoryCache(),
  });
